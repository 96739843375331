import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export const logos = [
  {
    image: (
      <StaticImage
        // className="h-12"
        objectFit="contain"
        src="../../images/logos/partner_antrophia.png"
        alt="Antrophia"
      />
    ),
  },
  {
    image: (
      <StaticImage
        // className="h-12"
        objectFit="contain"
        src="../../images/logos/partner_startplatz.png"
        alt="Startplatz"
      />
    ),
  },
  {
    image: (
      <StaticImage
        // className="h-12"
        objectFit="contain"
        src="../../images/logos/partner_impactfactory.png"
        alt="Impact Factory"
      />
    ),
  },
  {
    image: (
      <StaticImage
        // className="h-12"
        objectFit="contain"
        src="../../images/logos/partner_microsoftstartup.png"
        alt="Microsoft for Startups"
      />
    ),
  },
  {
    image: (
      <StaticImage
        // className="h-12"
        objectFit="contain"
        src="../../images/logos/partner_haniel.png"
        alt="Haniel"
      />
    ),
  },
  {
    image: (
      <StaticImage
        // className="h-12"
        objectFit="contain"
        src="../../images/logos/partner_techquartier.png"
        alt="TechQuartier"
      />
    ),
  },
  {
    image: (
      <StaticImage
        // className="h-12"
        objectFit="contain"
        src="../../images/logos/partner_fortomorrow.png"
        alt="ForTomorrow"
      />
    ),
  },
  {
    image: (
      <StaticImage
        // className="h-12"
        objectFit="contain"
        src="../../images/logos/partner_insurelab.png"
        alt="InsurLab Germany"
      />
    ),
  },
  {
    image: (
      <StaticImage
        // className="h-12"
        objectFit="contain"
        src="../../images/logos/partner_greentech.png"
        alt="GreenTech"
      />
    ),
  },
]
