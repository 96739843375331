import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
// import { graphql, useStaticQuery } from 'gatsby'
import Theme from '../components/theme'
import {
  SEO,
  Layout,
  Container,
  SectionHero,
  SectionRecentPosts,
  SectionLogoCloud,
  SectionText,
} from '../components'
import page from '../data/pages/h40.yml'

// import { Section } from '../components/Section/Section'
import { SectionNewsletter } from '../components/Section/SectionNewsletter'
import { logos } from '../data/static/logos'

function scripting() {
  return {
    __html: `
  <style>#pdg-9af2a52{margin-top: 10px; border-radius: 5px; opacity: .8;}</style>
  <script class="podigee-podcast-player" src="https://player.podigee-cdn.net/podcast-player/javascripts/podigee-podcast-player.js" data-configuration="https://handel40.podigee.io/80-80-payactive-nachhaltiges-bezahlen-im-e-commerce-im-talk-mit-grunder-mathias-born/embed?context=external&token=Hx8kT8sh0Xz0A_DKewTuRg"></script>
  `,
  }
}

// import logoSocialCashback from '../images/logo-socialcashback.svg'

const H40Page = (): React.ReactElement => {
  return (
    <>
      <SEO title={page?.title} />
      <Theme>
        <div className="fixed z-0 w-full h-full top-0 left-0 opacity-10">
          <StaticImage
            src="../images/bubbles.jpg"
            alt="Fische"
            objectFit="cover"
            className="w-full h-full select-none"
            draggable={false}
          />
        </div>
        <Layout>
          <SectionHero
            title="payactive @ H4.0"
            titleTag="h1"
            subtitle=""
            text="payactive Gründer Mathias war beim H4.0 Podcast und hat mit Paul über nachhaltiges Bezahlen im E-Commerce gesprochen. Wenn du selbst auf der Suche nach einer regenerativen Zahlungslösung bist, dann buche einfach eine Demo und wir führen dir unsere Plattform vor."
            actionLinks={[
              {
                label: 'Jetzt Demo buchen',
                url: 'demo',
              },
              {
                url: 'mailto:hello@payactive.eu?subject=Request for information',
                label: 'per E-Mail anfragen',
                variant: 'link',
                type: 'email',
              },
            ]}
            image={
              <StaticImage
                src="../images/h40visual.png"
                alt="Mathias von payactive beim H4.0 Podcast"
              />
            }
          />

          <SectionText title="Hier gibt's den Podcast" containerClass="pt-14" />
          <Container className="">
            <div dangerouslySetInnerHTML={scripting()} />
          </Container>

          <SectionText title="payactive im Einsatz" containerClass="pt-14" />
          <SectionRecentPosts />
          <SectionText
            title="Partner – Netzwerk"
            subtitle="Unternehmen, mit denen wir wachsen"
            containerClass="pt-14"
            centered
          />
          <SectionLogoCloud data={logos} />
          <SectionNewsletter containerClass="" page="Startseite" />
        </Layout>
      </Theme>
    </>
  )
}

export default H40Page
